<template>
  <div class="box wrapper">
    <el-breadcrumb separator="/" class="el-breadcrumb">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/EnterpriseService' }"
        >政务服务</el-breadcrumb-item
      >
      <el-breadcrumb-item>员工子女入学申报</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="content">
      <div class="title">
        <div class="line"></div>
        <div class="text">员工子女入学申报表</div>
      </div>
      <div class="search-box">
        <el-form
          ref="form"
          @submit.native.prevent
          label-width="84px"
          label-position="right"
        >
          <el-row :gutter="24">
            <el-col :span="6">
              <el-form-item label="学生姓名">
                <el-input
                  v-model="queryTable.condition.studentName"
                  :maxlength="15"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="学生性别">
                <el-select v-model="queryTable.condition.studentSex" clearable>
                  <el-option
                    v-for="item in studentSex"
                    :key="item.itemValue"
                    :label="item.itemDesc"
                    :value="item.itemValue"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="状态">
                <el-select v-model="queryTable.condition.status" clearable>
                  <el-option
                    v-for="item in status"
                    :key="item.itemValue"
                    :label="item.itemDesc"
                    :value="item.itemValue"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
                 <el-form-item label="升学情况">
                <el-select v-model="queryTable.condition.progression" clearable>
                 <el-option
                  v-for="(item, index) in grade"
                  :key="index"
                  :label="item.itemDesc"
                  :value="item.itemValue"
                ></el-option>
                </el-select>
              </el-form-item> 
            </el-col>

            <div class="search-btns">
              <el-button type="primary" @click="queryTable.query()"
                >搜索</el-button
              >
              <el-button @click="queryTable.reset()">重置</el-button>
            </div>
          </el-row>
          <el-row>
            <el-col :span="8">
                 <el-form-item label="上报时间">
                <el-date-picker
                  v-model="dateRange"
                  @change="getTime"
                  type="datetimerange"
                  placeholder="请选择活动时间"
                  range-separator="至"
                  value-format="yyyy-MM-dd HH-mm-ss"
                  :default-time="['00:00:00', '23:59:59']"
                ></el-date-picker>
              </el-form-item>
           
            </el-col>
          </el-row>
        </el-form>
      </div>
      <el-button type="primary" @click="add">新增</el-button>
      <!-- <el-button type="primary" >一键通知</el-button> -->
      <import-excel
        @cb="queryTable.query()"
        :file-type="['xlsx']"
        :size-limit="200"
        size-unit="M"
        style="margin: 0 10px; display: inline-block"
      ></import-excel>
      <el-button type="primary"
        ><el-link
          style="color: #fff"
          :underline="false"
          :href="attachmentUrl[5].attachmentUrl"
          >下载导入模板</el-link
        ></el-button
      >
      <el-button type="primary"
        ><el-link
          style="color: #fff"
          :underline="false"
          :href="attachmentUrl[6].attachmentUrl"
          >下载操作智引图</el-link
        ></el-button
      >
      <el-button type="primary" @click="handleBatchSubmit">批量提交</el-button>

      <div class="table-box">
        <el-table
          v-loading="loading"
          ref="multipleTable"
          @selection-change="handleSelectionChange"
          :data="queryTable.table"
          style="width: 100%"
          tooltip-effect="light"
        >
          <el-table-column type="selection" label="选择"></el-table-column>
          <el-table-column type="index" label="序号" width="50">
          </el-table-column>
          <!-- <el-table-column prop="parentName" label="家长姓名" width="83"></el-table-column>
          <el-table-column prop="parentPhone" label="家长联系电话" width="110" :formatter="formatterTelephone"></el-table-column> -->
          <el-table-column
            prop="studentName"
            label="学生姓名"
          ></el-table-column>
          <el-table-column
            prop="studentSex"
            label="性别"
            :formatter="formatterstudentSex"
          ></el-table-column>
          <el-table-column
            prop="progression"
            label="升学情况"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="currentSchool"
            label="现就读学校"
            show-overflow-tooltip
          ></el-table-column>
          <!-- <el-table-column prop="currentGrade" label="就读年级" width="130" show-overflow-tooltip></el-table-column> -->
          <!-- <el-table-column prop="progression" label="升学情况" ></el-table-column> -->
          <!-- <el-table-column prop="studentNum" label="学生身份证号" width="150" :formatter="formatterIdNumber"></el-table-column> -->
          <el-table-column prop="domicile" label="户籍"></el-table-column>
          <!-- <el-table-column prop="purposeTransfer" label="意向转入学校" width="120"></el-table-column> -->
          <el-table-column
            prop="realityTransfer"
            label="实际转入学校"
          ></el-table-column>
          <el-table-column
            prop="status "
            label="状态"
            :formatter="formatterStatus"
          >
            <template slot-scope="scope">
              <el-button
                @click="handleShowStatusDialog(scope.row)"
                style="color: #135694"
                type="text"
                >{{ formatterStatus(scope.row) }}</el-button
              >
            </template>
          </el-table-column>
          <el-table-column prop="createTime" label="上报时间"></el-table-column>
          <!-- <el-table-column prop="remarks" label="备注" show-overflow-tooltip></el-table-column> -->
          <!-- <el-table-column prop="companyName" label="所在企业" show-overflow-tooltip></el-table-column> -->
          <el-table-column label="操作" width="150">
            <template slot-scope="scope">
              <el-button
                @click="view(scope.row)"
                style="color: #135694"
                type="text"
                >查看</el-button
              >
              <el-button
                @click="edit(scope.row)"
                v-if="scope.row.status == 9 || scope.row.status == 5"
                style="color: #135694"
                type="text"
                >编辑</el-button
              >
              <el-button
                @click="del(scope.row.id)"
                v-if="scope.row.status == 9 || scope.row.status == 5"
                style="color: #135694"
                type="text"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <el-row style="margin-top: 25px">
          <el-pagination
            background
            layout="total, prev, pager, next, jumper"
            :page-size="queryTable.pageSize"
            :total="queryTable.count"
            :current-page.sync="queryTable.page"
            @current-change="queryTable.search()"
          ></el-pagination>
        </el-row>
      </div>
    </div>
    <!-- <div class="content" style="margin-top: 16px">
      <div class="title">
        <div class="line"></div>
        <div class="text">资料附件</div>
      </div>
    </div> -->
    <el-dialog
      :close-on-click-modal="false"
      title="员工子女入学申报"
      top="60px"
      width="1230px"
      :visible.sync="saveDialogVisable"
      :append-to-body="true"
      @closed="closed"
    >
      <el-button v-if="formDebug" @click="saveData = saveDebugData" type="primary"  size="mini">表单调试 快速填充</el-button>
      <el-form
        ref="saveForm"
        :model="saveData"
        :rules="saveRules"
        @submit.native.prevent
        label-width="150px"
        label-position="left"
      >
        <el-row :gutter="28">
          <el-col :span="8">
            <el-form-item label="家长姓名" prop="parentName">
              <el-input
                v-model="saveData.parentName"
                :disabled="isVivew"
                placeholder="请输入姓名"
                :maxlength="15"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="家长联系电话" prop="parentPhone">
              <el-input
                v-model="saveData.parentPhone"
                :disabled="isVivew"
                placeholder="请输入家长联系电话"
                :maxlength="11"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="28">
          <el-col :span="8">
            <el-form-item label="学生姓名" prop="studentName">
              <el-input
                v-model="saveData.studentName"
                :disabled="isVivew"
                placeholder="请输入学生姓名"
                :maxlength="10"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="性别" prop="studentSex">
              <!-- <el-radio-group v-model="saveData.studentSex">
                <el-radio v-for="(item, index) in studentSex" :key="index" :label="item.itemValue">{{ item.itemDesc }}</el-radio>
              </el-radio-group> -->
              <el-select v-model="saveData.studentSex" :disabled="isVivew">
                <el-option
                  v-for="(item, index) in studentSex"
                  :key="index"
                  :label="item.itemDesc"
                  :value="item.itemValue"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="学生身份证号" prop="studentNum">
              <el-input
                v-model="saveData.studentNum"
                :disabled="isVivew"
                placeholder="请输入学生身份证号"
                :maxlength="18"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="28">
          <el-col :span="8">
            <el-form-item label="现就读学校" prop="currentSchool">
              <el-input
                v-model="saveData.currentSchool"
                :disabled="isVivew"
                placeholder="请输入现就读学校"
                :maxlength="20"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="就读年级" prop="currentGrade">
              <el-input
                v-model="saveData.currentGrade"
                :disabled="isVivew"
                placeholder="请输入就读年级"
                :maxlength="20"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="升学情况" prop="progression">
              <el-select v-model="saveData.progression" :disabled="isVivew">
                <el-option
                  v-for="(item, index) in grade"
                  :key="index"
                  :label="item.itemDesc"
                  :value="item.itemValue"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="28">
          <el-col :span="8">
            <el-form-item label="户籍" prop="domicile">
              <el-input
                v-model="saveData.domicile"
                :disabled="isVivew"
                placeholder="请输入户籍"
                :maxlength="10"
              ></el-input>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="8">
            <el-form-item label="意向转入学校" prop="purposeTransfer">
              <el-select v-model="saveData.purposeTransfer">
                <el-option v-for="(item, index) in schools" :key="index" :label="item.itemDesc" :value="item.itemValue"></el-option>
              </el-select>
            </el-form-item>
          </el-col> -->
          <el-col :span="16">
            <el-form-item label="备注">
              <el-input
                v-model="saveData.remarks"
                :disabled="isVivew"
                :maxlength="200"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="28">
          <el-col :span="12">
            <div class="file">
              <div class="label">学籍基本信息(学籍网上打印),转出学校盖章:</div>
              <file-upload
                :file-type="[]"
                :files="fieldValue0"
                :limit="1"
                :size-limit="20"
                :disabled="isVivew"
                size-unit="M"
                :isImg="false"
                tip="文件大小不能超过20M"
                style="margin-left: 10px"
              ></file-upload>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="file">
              <div class="label">学生、家长户口本:</div>
              <file-upload
                :file-type="[]"
                :files="fieldValue1"
                :disabled="isVivew"
                :limit="1"
                :size-limit="20"
                size-unit="M"
                :isImg="false"
                tip="文件大小不能超过20M"
                style="margin-left: 10px"
              ></file-upload>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="28">
          <el-col :span="12">
            <div class="file">
              <div class="label">一年社保证明材料:</div>
              <file-upload
                :file-type="[]"
                :files="fieldValue2"
                :limit="1"
                :size-limit="20"
                :disabled="isVivew"
                size-unit="M"
                :isImg="false"
                tip="文件大小不能超过20M"
                style="margin-left: 10px"
              ></file-upload>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="file">
              <div class="label">暂住证:</div>
              <file-upload
                :file-type="[]"
                :files="fieldValue3"
                :limit="1"
                :size-limit="20"
                :disabled="isVivew"
                size-unit="M"
                :isImg="false"
                tip="文件大小不能超过20M"
                style="margin-left: 10px"
              ></file-upload>
            </div>
          </el-col>
          <el-col :span="12" style="padding-bottom: 0">
            <div class="file">
              <div class="label">劳动合同:</div>
              <file-upload
                :file-type="[]"
                :files="fieldValue4"
                :limit="1"
                :disabled="isVivew"
                :size-limit="20"
                size-unit="M"
                :isImg="false"
                tip="文件大小不能超过20M"
                style="margin-left: 10px"
              ></file-upload>
            </div>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="saveDialogVisable = false">取消</el-button>
        <el-button type="primary" @click="save()">保存</el-button>
      </div>
    </el-dialog>
    <el-dialog title="审核" :visible.sync="showStatusDialog">
      <el-table :data="statusDialogData">
        <el-table-column
          prop="operationDescription"
          label="审核描述"
        ></el-table-column>
        <el-table-column prop="statusName" label="当前状态"></el-table-column>
        <el-table-column
          prop="createTime"
          label="操作时间"
          :formatter="formatterDate"
        ></el-table-column>
        <el-table-column prop="userName" label="操作人"></el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import validate from "@/assets/js/validate";
import fileUpload from "@/components/FileUpload";
import importExcel from "./FileUpload";

import { QueryTable } from "@/assets/js/QueryTable";
import ListQuerytor from "@/common/ListQuerytorTs/index";
import { mapState } from "vuex";
import dayjs from "dayjs";
export default {
  name: "policyService",

  data() {
    let validatorTelephone = (rule, value, callback) => {
      if (value === "" || value === undefined) {
        callback(new Error("请输入手机号"));
        return;
      }
      if (validate.isValidPhone(value)) {
        callback();
      } else {
        callback(new Error("请输入正确的手机号"));
      }
    };
    let validatorIDNumber = (rule, value, callback) => {
      if (value === "" || value === undefined) {
        callback(new Error("请输入学生身份证号"));
        return;
      }
      if (validate.isValidIDNumber(value)) {
        callback();
      } else {
        callback(new Error("请输入正确的身份证号"));
      }
    };
    return {
      showStatusDialog: false,
      statusDialogData: [],
      curItem: {},
      dateRange: [],
      fieldValue0: [],
      fieldValue1: [],
      fieldValue2: [],
      fieldValue3: [],
      fieldValue4: [],
      loading: false,
      isVivew: false,
      status: [
        {
          itemValue: "1",
          itemDesc: "已提交审核中",
        },
        {
          itemValue: "2",
          itemDesc: "审核完成已通过",
        },
        {
          itemValue: "5",
          itemDesc: "企业已保存",
        },
        {
          itemValue: "9",
          itemDesc: "审核不通过",
        },
        {
          itemValue: "3",
          itemDesc: "实际转入学校已确认",
        },
      ],
      studentSex: [],
      schools: [
        {
          itemValue: "1",
          itemDesc: "1",
        },
        {
          itemValue: "2",
          itemDesc: "2",
        },
        {
          itemValue: "9",
          itemDesc: "3",
        },
      ],
      grade: [
        {
          itemValue: "小学一年级",
          itemDesc: "小学一年级",
        },
        {
          itemValue: "小学二年级",
          itemDesc: "小学二年级",
        },
        {
          itemValue: "小学三年级",
          itemDesc: "小学三年级",
        },
        {
          itemValue: "小学四年级",
          itemDesc: "小学四年级",
        },
        {
          itemValue: "小学五年级",
          itemDesc: "小学五年级",
        },
        {
          itemValue: "小学六年级",
          itemDesc: "小学六年级",
        },
        {
          itemValue: "初中一年级",
          itemDesc: "初中一年级",
        },
        {
          itemValue: "初中二年级",
          itemDesc: "初中二年级",
        },
        {
          itemValue: "初中三年级",
          itemDesc: "初中三年级",
        },
        {
          itemValue: "高中一年级",
          itemDesc: "高中一年级",
        },
        {
          itemValue: "高中二年级",
          itemDesc: "高中二年级",
        },
        {
          itemValue: "高中三年级",
          itemDesc: "高中三年级",
        },
      ],
      saveDialogVisable: false,
      saveData: {},
      saveInitData: {
        parentName: "",
        parentPhone: "",
        studentName: "",
        studentSex: "",
        currentSchool: "",
        currentGrade: "",
        progression: "",
        studentNum: "",
        // purposeTransfer: '',
        status: "1",
        domicile: "",
        remarks: "",
        files: [],
      },
      saveDebugData: {
        parentName: 1,
        parentPhone: 18058754636,
        studentSex: 1,
        studentNum: 330326199711026053,
        progression: "初中一年级",
        currentGrade: 1,
        currentSchool: 1,
        studentName: 1,
        domicile: 1,
        remarks: 1,
        files: [
          {
            fileName: "11",
            attachmentUrl:
              "/static//91330301MA2AWT8L6L/20210716/8cd435a0-f7d0-4dd6-9f7b-58c0cb0c6022.png",
          },
          {
            fileName: "11",
            attachmentUrl:
              "/static//91330301MA2AWT8L6L/20210716/3410d77f-a9b1-46f4-8cfe-e714a8f7d238.png",
          },
          {
            fileName: "11",
            attachmentUrl:
              "/static//91330301MA2AWT8L6L/20210716/8b072e7e-cb11-4424-bf9f-620dc8c94b0f.png",
          },
          {
            fileName: "11",
            attachmentUrl:
              "/static//91330301MA2AWT8L6L/20210716/26f422f3-0de7-46e3-8a3f-f376f34a45a1.png",
          },
          {
            fileName: "11",
            attachmentUrl:
              "/static//91330301MA2AWT8L6L/20210716/dbcb6b5d-14d5-4c13-85de-bf81ac204dc4.png",
          },
        ],
      },
      saveRules: {
        parentName: [
          { required: true, message: "请输入家长姓名", trigger: "blur" },
        ],
        parentPhone: [
          { required: true, validator: validatorTelephone, trigger: "blur" },
        ],
        studentName: [
          { required: true, message: "请输入学生姓名", trigger: "blur" },
        ],
        studentSex: [
          { required: true, message: "请选择性别", trigger: "blur" },
        ],
        currentSchool: [
          { required: false, message: "请输入现就读学校",validator:(rule, value, callback)=>{
              if(this.saveData.progression==="小学一年级"){
               callback();
              }
              if(!value || !value.length){
                callback(new Error("请输入现就读学校"))
              }
              callback();
          }, trigger: "blur" },
        ],
        currentGrade: [
          { required: false, message: "请输入就读年级", validator:(rule, value, callback)=>{
              if(this.saveData.progression==="小学一年级"){
               callback();
              }
              if(!value || !value.length){
                callback(new Error("请输入就读年级"))
              }
              callback();
          },trigger: "blur" },
        ],
        progression: [
          { required: true, message: "请输入升学情况", trigger: "blur" },
        ],
        studentNum: [
          { required: true, validator: validatorIDNumber, trigger: "blur" },
        ],
        // purposeTransfer: [{ required: true, message: '请输入意向转入学校', trigger: 'blur' }],
        domicile: [{ required: true, message: "请输入户籍", trigger: "blur" }],
        status: [{ required: true, message: "请选择状态", trigger: "blur" }],
      },
      queryTable: new QueryTable("employeechilddeclarat", {
        studentSex: "",
        studentName: "",
        createTimeStart: "",
        createTimeEnd: "",
        status: "",
      }),
      //多选
      multipleSelection: [],
    };
  },
  computed: {
    ...mapState({
      userStore: (state) => state.userStore,
      attachmentUrl: (state) => state.attachmentUrl,
    }),
    formDebug() {
      return this.$route.query.fromdebug;
    },
  },
  methods: {
    formatterDate(row) {
      return dayjs(row.createTime).format("YYYY-MM-DD");
    },
    async handleShowStatusDialog(row) {
      this.showStatusDialog = true;
      let res = await this.$api.getEmployeeChilddeClarat(row.id);
      console.log("审核信息返回值", res);
      this.statusDialogData = res.result;
      // this.curItem = row
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    async handleBatchSubmit() {
      if (!this.multipleSelection.length) {
        this.$message.error("请选择至少一条数据");
        return;
      }
      const ids = this.multipleSelection.map((item) => item.id).join(",");
      console.log("批量提交", ids);

      let { result } = await this.$api.employeechilddeclaratBatchSub({ ids });
      const h = this.$createElement;
      if (result.errorNum) {
        if (result.successNum) {
          this.$message({
            type: "warning",
            message: h("p", null, [
              h("b", { style: "color: #67C23A" }, `成功${result.successNum}条`),
              h(
                "b",
                { style: "color: #F56C6C" },
                `失败${result.errorNum}条, ${result.message}`
              ),
            ]),
          });
        } else {
          this.$message.error(
            `成功0条，失败${result.errorNum}条,${result.message}`
          );
        }
      } else {
        this.$message.success(`成功${result.successNum}条`);
      }
      // [()=> {
      //     this.$message({
      //        type: 'warning',
      //        message: h('p',null, [
      //           h('b', { style: 'color: #67C23A' }, '成功2 '),
      //           h('b', { style: 'color: #F56C6C' }, '失败1 请上传附件')
      //         ])
      //     })

      // },()=>  this.$message.success("成功2条"),()=>  this.$message.error("成功0，失败1 请上传附件")][type]()
      // this.$message.success(result.message)
      this.$refs.multipleTable.clearSelection();
      this.queryTable.search();
      console.log("批量提交返回值", result);
    },
    getTime(val) {
      if (val) {
        this.queryTable.condition.createTimeStart = val[0];
        this.queryTable.condition.createTimeEnd = val[1];
      } else {
        this.queryTable.condition.createTimeStart = "";
        this.queryTable.condition.createTimeEnd = "";
      }
    },
    closed() {
      this.$nextTick(() => {
        this.$refs["saveForm"].resetFields();
        this.isVivew = false;
        this.saveData = JSON.parse(JSON.stringify(this.saveInitData));
        this.fieldValue0 = [];
        this.fieldValue1 = [];
        this.fieldValue2 = [];
        this.fieldValue3 = [];
        this.fieldValue4 = [];
      });
    },
    add() {
      this.saveDialogVisable = true;
    },
    view(row) {
      this.isVivew = true;
      this.edit(row);
    },
    edit(row) {
      this.saveDialogVisable = true;
      this.$api.getemployeechilddeclaratInfo({ id: row.id }).then((res) => {
        this.saveData = JSON.parse(JSON.stringify(row));
        let files = res.result.files;
        for (let i = 0; i < files.length; i++) {
          this["fieldValue" + i].push(files[i]);
        }
      });
    },
    save() {
      console.log("点击保存",this.saveData, this.$refs["saveForm"])
      this.$refs["saveForm"].validate((valid) => {
        console.log("点击保存",valid,this.saveData)
        if (valid) {
          let tip = [
            "学籍基本信息(学籍网上打印),转出学校盖章",
            "学生、家长户口本",
            "一年社保证明材料",
            "暂住证",
            "劳动合同",
          ];
          let files = [];
          for (let i = 0; i < 5; i++) {
            if(this.formDebug)break;
            if (this["fieldValue" + i].length < 1) {
              if(this.saveData.progression === "小学一年级" && i === 0)continue;
              this.$message.error({ message: "请上传" + tip[i], offset: 80 });
              return;
            }
            files.push(this["fieldValue" + i][0]);
          }
          this.saveData.files = JSON.stringify(files);
          this.saveData.companyName = this.userStore.adminPersonalUserEntity
            ? ""
            : this.userStore.adminVo && this.userStore.adminVo.name
            ? this.userStore.adminVo.name
            : this.userStore.user.companyName;
          if (this.saveData.id) {
            this.$api.editemployeechilddeclarat(this.saveData).then((res) => {
              if (res.success) {
                this.$message.success({ message: "修改成功", offset: 80 });
                this.saveDialogVisable = false;
                this.queryTable.search();
              } else {
                this.$message.error({ message: res.message, offset: 80 });
              }
            });
          } else {
            this.$api.addemployeechilddeclarat(this.saveData).then((res) => {
              if (res.success) {
                this.$message.success({ message: "新增成功", offset: 80 });
                this.saveDialogVisable = false;
                this.queryTable.search();
              } else {
                this.$message.error({ message: res.message, offset: 80 });
              }
            });
          }
        } else {
          return false;
        }
      });
    },
    del(id) {
      this.$confirm(`确定删除此数据吗?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$api.delemployeechilddeclarat({ ids: id }).then((res) => {
          if (res.success) {
            this.$message.success({ message: "删除成功", offset: 80 });
            this.queryTable.search();
          } else {
            this.$message.error({ message: res.message, offset: 80 });
          }
        });
      });
    },
    formatterstudentSex(row) {
      let studentSex = this.studentSex.filter((v) => {
        return row.studentSex == v.itemValue;
      })[0];
      return studentSex ? studentSex.itemDesc : row.studentSex;
    },
    formatterStatus(row) {
      let status = this.status.filter((v) => {
        return row.status == v.itemValue;
      })[0];
      return status ? status.itemDesc : "";
    },
    formatterIdNumber(row) {
      var tel = row.studentNum + "";
      return tel.replace(tel.substring(6, 14), "********");
    },
    formatterTelephone(row) {
      var tel = row.parentPhone + "";
      return tel.replace(tel.substring(3, 7), "****");
    },
  },
  components: {
    fileUpload,
    importExcel,
  },
  created() {
    window.vue  =this;
    let listQuery = new ListQuerytor({
      model: "employeechilddeclarat",
      condition: {
        studentSex: "",
        studentName: "",
        createTimeStart: "",
        createTimeEnd: "",
        status: "",
      },
    });
    console.log("打印ListQuerytor实例", listQuery);

    this.queryTable.search();
    this.$api.getkey({ dictKey: "employee_child_status" }).then((res) => {
      this.status = res.result;
    });
    this.$api.getkey({ dictKey: "gender" }).then((res) => {
      this.studentSex = res.result;
    });
  },
};
</script>

<style lang="scss" scoped>
.search-box {
  margin-bottom: 16px;
}
.box {
  .content {
    padding: 20px;
    background-color: #fff;
    .title {
      display: flex;
      align-items: center;
      padding-bottom: 18px;
      border-bottom: 1px solid #dddddd;
      margin-bottom: 20px;
      .line {
        width: 4px;
        height: 20px;
        background: #4393f8;
        border-radius: 3px;
        margin-right: 10px;
      }
      .text {
        font-size: 26px;
        color: #333333;
        line-height: 36px;
        font-weight: 600;
      }
    }
    .table-box {
      margin-top: 20px;
    }
  }
}
.file {
  display: flex;
  line-height: 40px;
  .label {
    width: 150px;
    min-width: 150px;
    text-align: right;
  }
}
</style>
