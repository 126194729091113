<template>
  <el-upload
    ref="upload"
    :action="uploadUrl"
    :before-upload="(file) => beforeUpload(file, sizeLimit, sizeUnit)"
    :headers="headers"
    :disabled="disabled"
    name="uploadFile"
    :limit="9999"
    :show-file-list="false"
    :on-success="uploadSuccess"
  >
    <el-button type="primary" >批量上报</el-button>
  </el-upload>
</template>

<script>
import config from '@/http/config.js';
import { getToken } from '@/http/auth';

export default {
  props: {
    // 限制文件格式
    fileType: {
      type: Array,
      default: null,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
    sizeLimit: {
      type: Number,
      default: 20,
    },
    sizeUnit: {
      type: String,
      default: 'M',
    },
  },
  data() {
    return {
      uploadUrl: `${config.baseURL}/wztc/employeechilddeclarat/importExcel`,
      headers: { Authorization: getToken() || '' },
    };
  },
  methods: {
    beforeUpload(file, size, unit) {
      const fileSize = file.size;
      const sizeLimit = unit === 'M' ? size * 1024 * 1024 : size * 1024;
      if (fileSize > sizeLimit) {
        this.$message.error({ message: '上传文件不能超过' + size + unit, offset: 80 });
        return false;
      }

      if (this.$props.fileType.length === 0 || this.$props.fileType.indexOf('') > -1) {
        return true;
      } else {
        const suffix = file.name.substr(file.name.lastIndexOf('.') + 1).toLowerCase();
        if (this.$props.fileType.indexOf(suffix) > -1) {
          return true;
        } else {
          this.$message.error({ message: `只支持上传${this.$props.fileType.join()}格式文件`, offset: 80 });
          return false;
        }
      }

      // const suffixName = file.name.replace(/.+\./, '')
      // if (!this.fileLimit.includes(suffixName)) {
      //   const message = this.fileLimit.join('/')
      //   this.$message.error(`只支持${message}格式`)
      // }
      // return this.fileLimit.includes(suffixName)
    },
    uploadSuccess(res, file, fileList) {
      console.log(res, file, fileList);
      if (res.success) {
        this.$message.success({ message: res.message, offset: 80 });
        this.$emit('cb');
      } else {
        this.$message.error({ message: res.message, offset: 80 });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.upload-hidden {
  ::v-deep .el-upload {
    display: none;
  }
}
label {
  position: absolute;
  bottom: 0;
  left: -70px;
  font-size: 14px;
}
</style>
